
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { farmingJobTemplateList } from '@/types/farmingCalendar'
@Component({
  name: 'EditCalendar'
})

export default class extends Vue {
  @Prop({ default: false }) private isShowDialog!: boolean
  @Prop({ default: '' }) private calendarId!: string
  private form = { farmingMonth: '', farmingExplain: '' }
  // 表格
  private tableData: Array<farmingJobTemplateList> = []
  private loading = false
  // 作业类型
  private farmingType = []
  // 是否操作
  private isOperation = false

  created () {
    this.getFarmingType()
    this.calendarId && this.getData()
  }

  getFarmingType () {
    this.loading = true
    this.$axios.get(this.$apis.calendar.selectFarmingTypeByList).then(res => {
      this.farmingType = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.calendar.selectFarmingCalendar, {
      calendarId: this.calendarId
    }).then(res => {
      this.form.farmingMonth = this.handleDate(res.farmingMonth)
      this.form.farmingExplain = res.farmingExplain || '--'
      this.tableData = res.farmingJobList || []
    }).finally(() => {
      this.loading = false
    })
  }

  handleDate (date: string) {
    return date.split('-').length > 0 ? +date.split('-')[1] + '月' : '--'
  }

  // 默认1未完成 2已完成
  getStatus (index: string, isReverse?: boolean) {
    const status = ['未完成', '已完成']
    const statusReverse = ['已完成', '未完成']
    if (isReverse) {
      return statusReverse[+index - 1]
    }
    return status[+index - 1]
  }

  // 标记状态
  onMark (row: farmingJobTemplateList, index: number) {
    const jobId = row.jobId
    // 新增的
    if (!jobId) {
      if (this.tableData[index].status === '1') {
        this.tableData[index].status = '2'
      } else {
        this.tableData[index].status = '1'
      }
      this.$set(this.tableData, 'status', this.tableData[index].status)
    } else {
      this.$axios.post(this.$apis.calendar.updateFarmingJobStatus, { jobId: jobId }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    }
  }

  closeDialog () {
    this.$emit('closeDialog')
  }

  onAdd () {
    if (this.isOperation) {
      this.$message.warning('请先保存！')
    } else {
      this.isOperation = true
      this.tableData.push({
        farmingTypeId: '',
        content: '',
        status: '2', // 默认2已完成
        isEdit: true
      })
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (index: number) {
    if (this.isOperation) {
      this.$message.warning('请先保存！')
    } else {
      this.isOperation = true
      this.tableData[index].isEdit = true
      this.$set(this.tableData, 'isEdit', this.tableData[index].isEdit)
    }
  }

  onSave (row: farmingJobTemplateList, index: number) {
    ;(this.$refs.row as ElForm).validate(valid => {
      if (valid) {
        this.isOperation = false
        delete this.tableData[index].isEdit
        delete this.tableData[index].farmingTypeName
        // 修改
        const url = row.jobId ? this.$apis.calendar.updateFarmingJob : this.$apis.calendar.insertFarmingJob
        this.$axios.post(url, { ...row, calendarId: this.calendarId }).then(() => {
          this.$message.success('保存成功')
          this.getData()
        })
      }
    })
  }

  onDelete (row: farmingJobTemplateList, index: number) {
    const jobId = row.jobId
    if (!jobId) {
      this.tableData.splice(index, 1)
      this.isOperation = false
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$axios.post(this.$apis.calendar.deleteFarmingJob, { jobId: jobId }).then(() => {
          this.$message.success('删除成功')
          this.getData()
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }
}
