
import { Component, Vue } from 'vue-property-decorator'
import EditCalendar from './EditCalendar.vue'

@Component({
  components: { EditCalendar }
})
export default class extends Vue {
  private isShowDialog = false
  private cuurentYear = this.$dayjs().year().toString()
  private months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  // 当前年份
  private date = {
    year: this.cuurentYear,
    month: this.$dayjs().get('month')
  }

  private pickerOptions = {
    disabledDate: (val: string) => {
      return this.$dayjs(val).year() > this.cuurentYear
    }
  }

  private activeItem = this.date.month
  private loading = false
  // 日历列表
  private calendarlist: Array<{ calendarId: string; farmingMonth: string}> = []
  // 编辑
  private calendarId = ''

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.calendar.selectFarmingCalendarList, {
      year: this.date.year
    }).then(res => {
      this.calendarlist = res || []
    }).finally(() => {
      this.loading = false
    })
  }

  changeYear (val: string) {
    this.date.year = this.$dayjs(val).year().toString()
    // 默认当前年的月份
    this.activeItem = this.$dayjs().get('month')
    this.getData()
  }

  onClickItem (index: number) {
    this.activeItem = index
    this.calendarId = this.calendarlist[index] ? this.calendarlist[index].calendarId : ''
  }

  onEdit (index: number) {
    this.isShowDialog = true
    this.calendarId = this.calendarlist[index] ? this.calendarlist[index].calendarId : ''
  }

  closeDialog () {
    this.isShowDialog = false
    this.getData()
  }
}
